import React from 'react';
import Option from './Option';

import VegetarianIcon from '../../../../assets/images/vegetarian.png';
import VeganIcon from '../../../../assets/images/vegan.png';
import GlutenIcon from '../../../../assets/images/gluten.png';
import NutsIcon from '../../../../assets/images/nuts.png';
import PeanutsIcon from '../../../../assets/images/peanuts.png';
import FishIcon from '../../../../assets/images/fish.png';
import EggsIcon from '../../../../assets/images/eggs.png';
import CrustaceansIcon from '../../../../assets/images/crustaceans.png';
import MolluscsIcon from '../../../../assets/images/molluscs.png';
import MilkIcon from '../../../../assets/images/milk.png';
import SoyaIcon from '../../../../assets/images/soya.png';
import CeleryIcon from '../../../../assets/images/celery.png';
import MustardIcon from '../../../../assets/images/mustard.png';
import LupinIcon from '../../../../assets/images/lupin.png';
import SesameSeedsIcon from '../../../../assets/images/sesame_seeds.png';
import SulphiteIcon from '../../../../assets/images/sulphite.png';

export const Vegetarian = <Option key="vegetarian_friendly" requirement="vegetarian_friendly" icon={VegetarianIcon}/>;
export const Vegan = <Option key="vegan_friendly" requirement="vegan_friendly" icon={VeganIcon}/>;
// export const GlutenFree = <Option key='gluten_friendly' requirement='gluten_friendly' icon={GlutenIcon} />

export const NutAllergy = <Option key="contains_nuts" requirement="contains_nuts" icon={NutsIcon}/>;
export const PeanutsAllergy = <Option key="contains_peanuts" requirement="contains_peanuts" icon={PeanutsIcon}/>;
export const FishAllergy = <Option key="contains_fish" requirement="contains_fish" icon={FishIcon}/>;
export const EggsAllergy = <Option key="contains_eggs" requirement="contains_eggs" icon={EggsIcon}/>;
export const CrustaceansAllergy = <Option key="contains_crustaceans" requirement="contains_crustaceans" icon={CrustaceansIcon}/>;
export const MolluscsAllergy = <Option key="contains_molluscs" requirement="contains_molluscs" icon={MolluscsIcon}/>;
export const MilkAllergy = <Option key="contains_milk" requirement="contains_milk" icon={MilkIcon}/>;
export const SoyaAllergy = <Option key="contains_soya" requirement="contains_soya" icon={SoyaIcon}/>;
export const CeleryAllergy = <Option key="contains_celery" requirement="contains_celery" icon={CeleryIcon}/>;
export const MustardAllergy = <Option key="contains_mustard" requirement="contains_mustard" icon={MustardIcon}/>;
export const LupinAllergy = <Option key="contains_lupin" requirement="contains_lupin" icon={LupinIcon}/>;
export const SesameSeedsAllergy = <Option key="contains_sesame_seeds" requirement="contains_sesame_seeds" icon={SesameSeedsIcon}/>;
export const SulphiteAllergy = <Option key="contains_sulphite" requirement="contains_sulphite" icon={SulphiteIcon}/>;
export const CerealsWithGlutenAllergy = <Option key="contains_cereals_with_gluten" requirement="contains_cereals_with_gluten" icon={GlutenIcon}/>;

