import React from 'react';
import { axios } from "../../utilities/axios";
import MenuScene from '../Menu';
import DisclaimerScene from '../Disclaimer';
import RequirementsScene from '../Requirements';

export default class Master extends React.Component {
  constructor(props) {
    super(props);
    const {slug} = props.match.params;
    this.state = {slug, scene: 'loading'};
    this.fetchBranch(slug);
  }

  fetchBranch(slug) {
    axios.get('/api/public/v1/branches/' + slug)
      .then(res => this.setState({branch: res.data, scene: 'disclaimer'}))
      .catch(err => {
        const error = err.response.status === 404 ? 'Branch Not Found' : 'Unknown';
        this.setState({scene: 'error', error});
      });
  }

  navigate = scene => {
    window.scrollTo({top: 0});
    this.setState({scene});
  };

  render() {
    switch (this.state.scene) {
      case 'loading':
        return <div/>;
      case 'disclaimer':
        return <DisclaimerScene branch={this.state.branch} navigate={this.navigate.bind(this)}/>;
      case 'requirements':
        return <RequirementsScene branch={this.state.branch} navigate={this.navigate.bind(this)}/>;
      case 'menu':
        return <MenuScene branch={this.state.branch} menus={this.state.branch.menus} navigate={this.navigate.bind(this)}/>;
      default:
        return <p>{this.state.error}</p>;
    }
  }
}