import { suitableColor } from './menuItem';

export const menuWithoutUnsuitableItems = (menu, activeRequirements) => {
  const shouldRenderItem = menuItem => suitableColor(menuItem, activeRequirements) !== 'red';

  return {
    ...menu,
    categories: menu.categories.map(category => {
      return {
        ...category,
        items: category.items.filter(shouldRenderItem),
      };
    }).filter(c => c.items.length > 0),
  };
};