import React from 'react';
import Collapsible from 'react-collapsible';
import ActiveIcon from '../../../../assets/images/expand-icon.png';
import InactiveIcon from '../../../../assets/images/expand-icon-disabled.png';
import MenuItem from '../MenuItem';
import './MenuCategory.scss';

export default class MenuCategory extends React.Component {
  captureEvent = () => {
    window.posthog.capture("open_menu_category", {
      category: this.props.category.name,
    });
  };

  render() {
    const items = this.props.category.items.filter(item => item.hidden_branches.every(branch => parseInt(branch) !== parseInt(this.props.branchId)));

    if (items.length === 0) {
      return null;
    }

    return <Collapsible classParentString="MenuCategory" trigger={this.renderTrigger()} onOpen={this.captureEvent}>
      {items.map((item, i) => <MenuItem key={i} item={item} requirements={this.props.requirements}/>)}
    </Collapsible>;
  }

  renderTrigger() {
    return <div className="category-trigger">
      <p>{this.props.category.name}</p>
      <img className="inactive" src={InactiveIcon} alt=""/>
      <img className="active" src={ActiveIcon} alt=""/>
    </div>;
  }
}