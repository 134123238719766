import './Option.scss';
import React from 'react';
import { connect } from 'react-redux';
import { humanizeRequirement, toggleRequirement } from '../../../../store/requirements';

class Option extends React.Component {
  render() {
    const {requirement, icon, active} = this.props;
    const title = humanizeRequirement(requirement);

    return <div className="Option" onClick={this.props.onClick}>
      <div className={`icon ${active ? 'active' : ''}`}>
        <img src={icon} alt=""/>
      </div>

      <p>{title}</p>
    </div>;
  }
}

const mapStateToProps = (state, props) => ({
  active: state[props.requirement],
});

const mapDispatchToProps = (dispatch, props) => ({
  onClick: () => dispatch(toggleRequirement(props.requirement)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Option);

