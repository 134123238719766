import React from 'react';
import './Disclaimer.scss';

export default class DisclaimerScene extends React.Component {
  render() {
    let logo = <p>{this.props.branch.full_name}</p>;
    if (this.props.branch.logo_url) {
      logo = <img src={this.props.branch.logo_url} alt="Logo"/>;
    }

    return <div className="DisclaimerScene scene">
      <div className="logo-container">
        {logo}
      </div>

      <div className="details">
        <h1>Disclaimer</h1>
        <p>{this.props.branch.primary_disclaimer}</p>
      </div>

      <div className="actions">
        <button className="primary" onClick={this.accept.bind(this)}>Accept</button>
      </div>
    </div>;
  }

  accept() {
    this.props.navigate('requirements');
  }
}