import React from 'react';
import Collapsible from 'react-collapsible';
import TrafficIcon from '../TrafficIcon';
import ActiveIcon from '../../../../assets/images/expand-icon.png';
import InactiveIcon from '../../../../assets/images/expand-icon-disabled.png';
import { groupedAllergens, suitableColor } from '../../../../helpers/menuItem';
import './MenuItem.scss';
import { humanizeRequirement } from '../../../../store/requirements';

export default class MenuItem extends React.Component {
  captureEvent = () => {
    window.posthog.capture("open_menu_item", {
      id: this.props.item.id,
      item: this.props.item.name,
    });
  };

  render() {
    const {item, requirements} = this.props;
    const color = suitableColor(item, requirements);

    return <Collapsible classParentString="MenuItem" trigger={this.renderTrigger(color)} onOpen={this.captureEvent}>
      <div className="inner">
        {this.renderInfo(color)}
        {this.renderSuitableFor()}
        {this.renderAllergens()}
      </div>
    </Collapsible>;
  }

  renderTrigger(color) {
    return <div className="item-trigger">
      <TrafficIcon color={color}/>
      <p>{this.props.item.name}</p>
      <img className="inactive" src={InactiveIcon} alt=""/>
      <img className="active" src={ActiveIcon} alt=""/>
    </div>;
  }

  renderInfo(color) {
    return <p className={`info ${color}`}>
      {this.props.item.notes}
    </p>;
  }

  renderSuitableFor() {
    const {item} = this.props;

    const renderRow = (key, humanizedKey) => {
      let color = 'red';

      switch (item[key]) {
        case 'yes':
          color = 'green';
          break;
        case 'no':
          color = 'red';
          break;
        case 'modifiable':
          color = 'orange';
          break;
      }

      return <div className="row">
        <TrafficIcon color={color}/>
        <p>{humanizedKey}</p>
      </div>;
    };

    return <div className="suitable-for">
      <div className="body">
        {renderRow("vegetarian_friendly", "Vegetarian")}
        {renderRow("vegan_friendly", "Vegan")}
        {/* { renderRow("gluten_friendly", "Gluten Free") } */}
      </div>
    </div>;
  }

  renderAllergens() {
    const data = groupedAllergens(this.props.item);

    const renderSection = (key, humanizedKey, color) => {
      if (!data[key]) {
        return;
      }

      return <div className="row">
        <div className="upper">
          <TrafficIcon color={color}/>
          <p>{humanizedKey}</p>
        </div>

        <div className="lower">
          <p>{(data[key] || []).map(humanizeRequirement).join(', ')}</p>
        </div>
      </div>;
    };

    return <div className="allergens">
      {renderSection('free_from', 'Free from', 'green')}
      {renderSection('modifiable', 'Ask to modify', 'blue')}
      {renderSection('cross_contamination', 'Cross contamination', 'orange')}
      {renderSection('contains', 'Contains', 'red')}
    </div>;
  }

}