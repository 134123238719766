import Cookie from 'react-cookies';

export const requirements = {
  "vegetarian_friendly": "Vegetarian",
  "vegan_friendly": "Vegan",
  // "gluten_friendly": "Gluten-free",
  "contains_nuts": "Nuts",
  "contains_peanuts": "Peanuts",
  "contains_fish": "Fish",
  "contains_eggs": "Eggs",
  "contains_crustaceans": "Crustaceans",
  "contains_molluscs": "Molluscs",
  "contains_milk": "Milk",
  "contains_soya": "Soya",
  "contains_celery": "Celery",
  "contains_mustard": "Mustard",
  "contains_lupin": "Lupin",
  "contains_sesame_seeds": "Sesame Seeds",
  "contains_sulphite": "Sulphite",
  "contains_cereals_with_gluten": "Cereals w Gluten",
};

export const humanizeRequirement = key => requirements[key];

export const toggleRequirement = key => {
  return {
    type: 'TOGGLE_REQUIREMENT',
    payload: key,
  };
};

export default (state = defaultState(), action) => {
  switch (action.type) {
    case "TOGGLE_REQUIREMENT": {
      const newState = {...state, [action.payload]: !state[action.payload]};

      const activeRequirements = Object.keys(newState).filter(k => newState[k]);
      Cookie.save('activeRequirements', activeRequirements.join(','), {path: '/'});

      return newState;
    }
    default :
      return state;
  }
}

const defaultState = () => {
  const activeRequirements = (Cookie.load('activeRequirements') || '').split(',');

  return Object.keys(requirements).reduce((map, key) => {
    map[key] = activeRequirements.includes(key);
    return map;
  }, {});
};