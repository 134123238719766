import React from 'react';
import { connect } from 'react-redux';
import MenuKey from './components/MenuKey';
import MenuSection from './components/MenuSection';
import { humanizeRequirement } from '../../store/requirements';
import './Menu.scss';

class Menu extends React.Component {

  state = {showUnsuitable: false};

  filteredMenus() {
    return this.props.menus.filter(m => m.visible);
  }

  render() {
    const menus = this.filteredMenus();

    let disclaimer;
    if (this.props.branch.secondary_disclaimer) {
      disclaimer = <p className="warning">{this.props.branch.secondary_disclaimer}</p>;
    }

    return <div className="scene Menu">
      <MenuKey
        showUnsuitable={this.state.showUnsuitable}
        toggleUnsuitable={this.toggleUnsuitable.bind(this)}/>

      <div className="inner">
        {this.renderSelections()}
        {disclaimer}
        {this.renderToggle()}
        {menus.length > 0 ? this.renderMenus(menus) : this.renderNoMenus()}

        <div className="footer">
          <p>Powered by <a href="https://dietarymenu.com" target="blank">Dietary Menu</a></p>
        </div>
      </div>
    </div>;
  }

  renderMenus() {
    const menus = this.filteredMenus();

    return menus.sort((a, b) => a.sorting_order - b.sorting_order).map((m, i) => <MenuSection
      openDefault={menus.length === 1}
      branchId={this.props.branch.id}
      key={i}
      menu={m}
      requirements={this.props.requirements}
      showUnsuitable={this.state.showUnsuitable}/>);
  }

  renderNoMenus() {
    return <div className={"selections text-center"}>
      <p>No menus available for this branch.</p>
    </div>;
  }

  renderToggle() {
    return <div className="toggle">
      <p>Show Unsuitable</p>

      <div
        className={`outer ${this.state.showUnsuitable ? 'active' : 'inactive'}`}
        onClick={() => this.setState({showUnsuitable: !this.state.showUnsuitable})}
      >
        <div className="inner"/>
      </div>
    </div>;
  }

  renderSelections() {
    return <div className="selections">
      <div className="upper">
        <h2>Your dietary requirements/allergens:</h2>
        <p className="edit" onClick={this.editSelections.bind(this)}>Edit</p>
      </div>

      <div className="lower">
        {
          this.props.requirements.length > 0
            ? this.props.requirements.map(s => <p key={s}>{humanizeRequirement(s)}</p>)
            : <span className={"none-selected"}>None selected</span>
        }
      </div>
    </div>;
  }

  editSelections = () => this.props.navigate('requirements');

  toggleUnsuitable = () => this.setState({showUnsuitable: !this.state.showUnsuitable});
}

const mapStateToProps = (state, props) => ({
  requirements: Object.keys(state).filter(k => state[k]),
});

const mapDispatchToProps = (dispatch, props) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
