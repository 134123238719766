import React from 'react';
import Collapsible from 'react-collapsible';
import Icon from '../../../../assets/images/expand-icon.png';
import MenuCategory from '../MenuCategory';
import { menuWithoutUnsuitableItems } from '../../../../helpers/menu';
import './MenuSection.scss';

export default class MenuSection extends React.Component {
  captureEvent = () => {
    window.posthog.capture("open_menu_section", {
      branch: this.props.branchId,
      menu: this.props.menu.name,
    });
  };

  render() {
    const {showUnsuitable, menu, requirements, openDefault, branchId} = this.props;
    const filteredMenu = showUnsuitable ? menu : menuWithoutUnsuitableItems(menu, requirements);

    return <Collapsible classParentString="MenuSection" trigger={this.renderTrigger()} open={openDefault} onOpen={this.captureEvent}>
      {filteredMenu.categories.map((c, i) => <MenuCategory key={i} category={c} requirements={this.props.requirements} branchId={branchId}/>)}
      {filteredMenu.categories.length === 0 ? <p className="none-available">No items in this menu match your criteria</p> : null}
    </Collapsible>;
  }

  renderTrigger() {
    return <div className="section-trigger">
      <p>{this.props.menu.name}</p>
      <img src={Icon} alt=""/>
    </div>;
  }
}