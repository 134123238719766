import React from 'react';
import './MenuKey.scss';
import TrafficIcon from '../TrafficIcon';

export default class MenuKey extends React.Component {
  render() {
    return <div className="MenuKey">
      <div className="color-keys">
        <div className={"key-col"}>
          <div className="eat">
            <TrafficIcon color="green"/>
            <p>Free from</p>
          </div>

          <div className="modify">
            <TrafficIcon color="blue"/>
            <p>Ask to modify</p>
          </div>
        </div>

        <div className={"key-col"}>
          <div className="cross">
            <TrafficIcon color="orange"/>
            <p>Cross contamination</p>
          </div>

          <div className="unsuitable">
            <TrafficIcon color="red"/>
            <p>Contains</p>
          </div>
        </div>
      </div>
    </div>;
  }
}