import { requirements } from '../store/requirements';

export const groupedAllergens = menuItem => {
  const keys = Object.keys(requirements).filter(r => r.includes('contains')); // exclude friendly, e.g. vegan
  let results = {};

  keys.forEach(key => {
    const val = menuItem[key]; // e.g. 'contains'
    if (results[val] === undefined) {
      results[val] = [];
    }
    results[val].push(key);
  });

  return results;
};

export const suitableColor = (menuItem, activeRequirements) => {
  let color = 'green';
  const colors = ['green', 'blue', 'orange', 'red'];

  // Don't "downgrade" value
  const updateColor = (newVal) => {
    const currentIndex = colors.indexOf(color);
    const newIndex = colors.indexOf(newVal);
    if (currentIndex < newIndex) {
      color = newVal;
    }
  };

  Object.keys(requirements).filter(r => r.includes('friendly')).forEach(key => {
    if (activeRequirements.includes(key)) {
      switch (menuItem[key]) {
        case 'yes':
          updateColor('green');
          break;
        case 'modifiable':
          updateColor('blue');
          break;
        case 'no':
          updateColor('red');
          break;
      }
    }
  });

  Object.keys(requirements).filter(r => r.includes('contains')).forEach(key => {
    if (activeRequirements.includes(key)) {
      switch (menuItem[key]) {
        case 'free_from':
          updateColor('green');
          break;
        case 'modifiable':
          updateColor('blue');
          break;
        case 'cross_contamination':
          updateColor('orange');
          break;
        case 'contains':
          updateColor('red');
          break;
        default:
          updateColor('red');
          break;
      }
    }
  });

  return color;
};