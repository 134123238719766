import React from 'react';
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';

const rollbarConfig = {
  accessToken: '8deb2830c2ad450fb796c9c87483c9eb',
  environment: 'production',
  server: {
    root: "https://app.dietarymenu.com/",
    branch: "main",
  },
};

export default class Rollbar extends React.Component {
  render() {
    if (process.env.NODE_ENV !== 'development') {
      return (
        <ErrorBoundary config={rollbarConfig}>
          <RollbarProvider config={rollbarConfig}>
            {this.props.children}
          </RollbarProvider>
        </ErrorBoundary>
      );
    } else {
      return this.props.children;
    }
  }
}
